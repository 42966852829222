import React, { useEffect } from 'react'
import { useContext } from '~/hooks'
import { navigate, PageProps } from 'gatsby'
import { Head, Content } from '~/components'

const PasswordSent = ({ location }: PageProps) => {
  const { token, setLoginReturn } = useContext()
  useEffect(() => {
    if (!token) {
      setLoginReturn(location.pathname)
      navigate('/')
    }
  }, [])

  return (
    <>
      <Head location={location} />
      <Content location={location} />
    </>
  )
}

export default PasswordSent
